import React from 'react';
import * as PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';
import { FormattedMessage } from "react-intl";


import Layout from '../components/layout';

const propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const TermTemplate = ({data, location}) => {
  const {title, node_locale, definition} = data.contentfulTerm;
  return (
    <Layout data={data} location={location}>
      <article className="TermPage Page">
        <section className="container grid-lg">
          <Link to={`/${node_locale}/glossary`}>
            <h5 className="text-color-5">
              <FormattedMessage id="viewGlossary" />
            </h5>
          </Link>

          <br />
          <h4>{title}</h4>
          <div>
            {/* this is marked as required in contentful so it *should* always be available  */}
            {definition.definition.split("\n").map((paragraph, i) => (
              <p key={i}>{paragraph}</p>
            ))}
          </div>
        </section>
      </article>
    </Layout>
  );
};

TermTemplate.propTypes = propTypes;

export default TermTemplate;

export const pageQuery = graphql`
  query termQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulTerm(id: {eq: $id}) {
      id
      node_locale
      title
      definition {
        id
        definition
      }
    }
  }
`;
